<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <!--class="bonus-background"-->
    <!--<v-container fluid>-->
        <v-card class="transparent-body" elevation="0">
            <!--id="Holder-v-card"-->
            <!--@click.self.prevent-->
            <!--<v-card-title dense dark color="pink darken-4">Список пользователей</v-card-title>-->
            <v-sheet elevation="12">
                <v-toolbar dense dark color="indigo darken-4">
                    <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
                    <v-toolbar-title>{{userTitle}}</v-toolbar-title>
                </v-toolbar>
                <!--center-active-->
                <!--centered-->
                <v-tabs
                        background-color="indigo"
                        dark
                        right
                        show-arrows
                >
                    <v-tab
                            v-for="tabitem in tablist"
                            :key="tabitem.value"
                            :disabled="!((tabitem.visible == 'all' || tabitem.visible == holderType) && (tabitem.new || holderId))"
                            :to="'/holder/' + (holderId ? holderId : 'add') + tabitem.href"
                    >
                        {{ tabitem.text }}
                    </v-tab>
                </v-tabs>
            </v-sheet>

            <v-container fluid class="d-flex justify-center px-0">
                <router-view
                    @update-holder="updateHolder">
                </router-view>
            </v-container>
            <!--<v-tabs-items v-model="tab2">-->
                <!--<v-tab-item-->
                        <!--v-for="tabitem in tablist"-->
                        <!--:key="tabitem.value"-->
                        <!--:value="'/holder/' + (holderId ? holderId : 'add') + tabitem.href"-->
                <!--&gt;-->
                <!--&lt;!&ndash;:to="'/holder/' + (holderId ? holderId : 'add') + tabitem.href"&ndash;&gt;-->
                    <!--<v-container fluid class="d-flex justify-center pt-2 pa-0">-->
                        <!--<router-view-->
                                <!--v-if="tab2 === '/holder/' + (holderId ? holderId : 'add') + tabitem.href"-->
                                <!--@update-holder="updateHolder">-->
                            <!--&lt;!&ndash;v-if="true"&ndash;&gt;-->
                        <!--</router-view>-->
                    <!--</v-container>-->
                <!--</v-tab-item>-->
            <!--</v-tabs-items>-->

            <v-tabs
                    v-if="false"
                    background-color="indigo"
                    dark
                    right
                    show-arrows
                    v-model="tab"
            >
                <v-tab
                        v-for="tabitem in tablist"
                        :key="tabitem.value"
                        :disabled="!((tabitem.visible == 'all' || tabitem.visible == holderType) && (tabitem.new || holderId))"
                        :href="'#' + tabitem.value"
                >
                    {{ tabitem.text }}
                </v-tab>

                <v-tabs-items v-model="tab">
                <v-tab-item
                        v-for="tabitem in tablist"
                        :key="tabitem.value"
                        :value="tabitem.value"
                >
                    <!--v-for="n in 5"-->
                    <!--:key="n"-->
                    <!--transition="scale-transition"-->
                    <!--reverse-transition="scale-transition"-->
                    <v-container fluid v-if="tabitem.value == 'holder'" class="d-flex justify-center pt-2 pa-0">
                        <holder-form :holder="holderId" @update-holder="updateHolder"></holder-form>
                    </v-container>
                    <v-container fluid v-else-if="tabitem.value == 'account'" class="d-flex justify-center pt-2 pa-0">
                        <account-form :holder="holderId"></account-form>
                    </v-container>

                    <v-container fluid v-else-if="tabitem.value == 'catalog'" class="d-flex justify-center pt-2 pa-0">
                        <holder-catalog-form :holder="holderId"></holder-catalog-form>
                    </v-container>

                    <v-container fluid v-else class="pa-0">
                        <v-row>
                            <v-col
                                    v-for="i in 6"
                                    :key="i"
                                    cols="12"
                                    md="4"
                                    sm="6"
                                    lg="3"
                            >
                                <v-img
                                        :src="`https://picsum.photos/500/300?image=${i * i * 5 + 10}`"
                                        :lazy-src="`https://picsum.photos/10/6?image=${i * i * 5 + 10}`"
                                        aspect-ratio="1"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-card>
    <!--</v-container>-->
</template>

<script>
  import HolderForm from '@/components/HolderForm';
  import AccountForm from '@/components/AccountForm';
  import HolderCatalogForm from '@/components/HolderCatalogForm';

  export default {
    name: 'Holder',
//    props: {holderType: String},
    props: ['mode'],

    /*beforeRouteUpdate (to, from, next) {
      //console.log(to);
      if (to.params.holderId == 'add'){
        this.holderId = null;
        //this.userTitle = 'Новый';
      }
      else if (!isNaN(parseInt(to.params.holderId,10))){
        //потом сделать наоборот, если цифра то гет данные иначе новый
        this.holderId = parseInt(to.params.holderId,10);
      }

      next();
    },*/

    computed: {
      tab: {
        set(tab){
          this.$router.replace({ query: { ...this.$route.query, tab } });
        },
        get(){
          return this.$route.query.tab;
        }
      }
    },

    watch: {
      mode: {
        handler: function(newVal, oldVal) {
          //console.log(oldVal,newVal);
          if (newVal == 'add'){
            this.holderId = null;
          }
          else if (!isNaN(parseInt(newVal,10))){
            this.holderId = parseInt(newVal,10);
          }
        },
        //immediate: true
      },
      holderId: {//это гавно конечно полное учитывая код сверху но это появилось первым
        handler: function(newVal, oldVal) {
          if (oldVal !== newVal && this.$route.name !== 'Common'){
            this.get(newVal);
          }
        },
        //immediate: true
        },
    },

    data(){
      return {
        tablist: [
          {text: 'Общее', value: 'holder', visible: 'all', href: '', new: true},
          {text: 'Счет', value: 'account', visible: 'all', href: '/account', new: false},
          {text: 'Каталог', value: 'catalog', visible: 'partner', href: '/catalog', new: false},
          {text: 'Акции', value: 'discount', visible: 'partner', href: '/discount', new: false},
          {text: 'Сертификаты', value: 'pinpay', visible: 'all', href: '/paypin', new: false},
          {text: 'Authkey', value: 'authkey', visible: 'partner', href: '/authkey', new: false}],
        holderId: null,
        holderType: null,
        userTitle: '',
        //tab: null
        //tab2: null
      }
    },
    components: {
      HolderForm,
      AccountForm,
      HolderCatalogForm
    },

    mounted() {
      //console.log(this.$route.path);
      //уже склоняюсь к варианту переключать вкладки гетом, и все закешировано и стейтфул некий
      if (this.mode == 'add'){
        this.holderId = null;
        //this.userTitle = '<Новый>';
      }
      else if (!isNaN(parseInt(this.mode,10))){
        //потом сделать наоборот, если цифра то гет данные иначе новый
        this.holderId = parseInt(this.mode,10);
      }
      else{
        this.$router.go(-1);//переделать на сторе вызов
      }

      //console.log(this.$route);
      //console.log(this.mode);
    },
    methods: {
      updateHolder(data){//тут может быть гавно в том что есть не все поля
        //console.log(data);
        //console.log(this.tab);
        this.holderId = data.holderId;
        this.userTitle = data.title;
        this.holderType = data.type;
      },

      updateUserTitle(title){
        this.userTitle = title;
      },

      get(id){
        this.$store.dispatch("setLoading", true);
        try{
          this.$store.dispatch("getHolder", id)
            .then((response) =>{
              if (response.data){
                this.holderId = response.data.id;
                this.userTitle = response.data.name;
                this.holderType = response.data.type;
              }
              else{
                throw new Error("Ошибка получения данных");
              }
            })
            .then(null, (error) =>{
              this.$store.dispatch("showMessage", { text: 'Ошибка получения holder: ' + error.message, error: true });
            })
            .finally(() =>{
              this.$store.dispatch("setLoading", false);
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },

    }
  };

/*
  One last thing — a button linking to a specific tab will simply look as follows:
  <v-btn to="/page?tab=one">
    Tab One
  </v-btn>
  Note that the button destination can be written as: :to="{ path: '/', query: { tab: 'two' } }" if you prefer the object syntax.
  The same will also work for router links.
*/
</script>

<style scoped>
    .transparent-body {
        background: transparent
    }
/*
    .v-tabs__content {
        background-color: green;
        !*height: 100px;*!
    }
*/
/*
    .v-tabs-items {
        !*background-color: rgb(0, 0, 0, 0.0);*!
        background: transparent
        !*height: 100px;*!
    }
*/
/*
    .v-window-item--active {
        background-color: rgb(0, 0, 0, 0.0);
        color: rgb(0, 0, 0, 0.0);
        !*height: 100px;*!
    }
*/
</style>
