<template>
    <!--<v-card max-width="600" class="pa-4 grey darken-4">-->
    <div class="flex-grow-1">
        <v-scale-transition>
            <!--<v-container class="pa-4 grey darken-4" v-show="show">-->
            <v-row v-show="show" class="justify-center ma-0">
                <v-col cols="12" md="8" sm="10" lg="6" class="pa-0">
                    <v-toolbar dense dark color="blue-grey darken-4" elevation="0">
                        <v-toolbar-title>Информация</v-toolbar-title>
                    </v-toolbar>

                    <v-sheet elevation="12" class="blue-grey darken-3 pa-4">
                        <v-form class=""
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row class="mb-3">
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-select
                                            v-model="type"
                                            :items="typeItems"
                                            :rules="[v => !!v || 'Item is required']"
                                            label="Тип"
                                            required
                                            readonly
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-select
                                            v-model="status"
                                            :items="statusItems"
                                            :rules="[v => !!v || 'Item is required']"
                                            label="Статус"
                                            required
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <!--:counter="10"-->
                            <v-text-field
                                    class="mb-1"
                                    v-model="name"
                                    :rules="nameRules"
                                    label="Полное имя"
                                    required
                                    outlined
                            ></v-text-field>

                            <!--:counter="10"-->
                            <v-text-field
                                    class="mb-1"
                                    v-model="shortName"
                                    :rules="shortNameRules"
                                    label="Короткое имя"
                                    required
                                    outlined
                            ></v-text-field>

                            <v-select
                                    class="mb-1"
                                    v-model="cities"
                                    :items="cityList"
                                    item-text="text"
                                    item-value="value"
                                    label="Город присутствия"
                                    multiple
                                    outlined
                                    :rules="[ v => !!v && v.length > 0 || 'Пустой выбор']"
                                    required
                                    hint="Список городов присутствия"
                                    persistent-hint
                                    :menu-props="{ closeOnContentClick: true, bottom: true, offsetY: true}"
                            ></v-select>
                            <!--clearable-->
                            <!--return-object-->

                            <v-text-field
                                    class="mb-1"
                                    v-model="ref"
                                    :rules="[v => !!v || 'Обязательное поле', refValidate]"
                                    label="Ref"
                                    required
                                    outlined
                                    hint="Последняя часть после :: используется как URL партнера в каталоге"
                                    persistent-hint
                                    :readonly="!!holderId"
                            ></v-text-field>

                            <v-row class="mb-1">
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-text-field
                                            v-model="createTime"
                                            label="Создан UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-1">
                                    <v-text-field
                                            v-model="updateTime"
                                            label="Изменен UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>
                        <v-card-actions class="pa-0">
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click.stop="submit" :disabled="!valid">{{!holder ? 'Добавить' : 'Изменить'}}</v-btn>
                            <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                        </v-card-actions>
                        <!--</v-card>-->
                    </v-sheet>
                </v-col>
            </v-row>
            <!--</v-container>-->
        </v-scale-transition>
    </div>
</template>

<script>
  export default {
    name: "HolderForm",
    props: ['holder'],

    watch:{
      holder:
        function(v){
          //console.log(v);
          this.init();
        },

      name:
        function(value) {
          if (!this.holder) {
            this.shortName = value;
          }
        },
      shortName:
        function(value) {
          if (!this.holder) {
            this.ref = 'holder::partner::' + this.transliterate(value).toLowerCase();
          }
        },
      // valid: function(value){
      //   this.disabled = ! this.valid;
      // }
    },

    data: () => ({
      valid: false,
      show: false,
      //disabled: true,
      holderId: null,
      shortName: '',
      name: '',
      nameRules: [
        v => !!v || 'Обязательное поле',
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      shortNameRules: [
        v => !!v || 'Обязательное поле',
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      ref: '',
      refRules: [
        v => !!v || 'Ref is required',
        v => /holder::partner::(\w+)$/.test(v) || 'Обязательное поле'
//        v => /.+@.+\..+/.test(v) || 'Ref must be valid',
      ],
      type: {},
      typeItems: [
        {text: 'Партнер', value:'partner'},
        {text: 'Клиент', value:'person'},
        {text: 'Организация', value:'organization'},
      ],
      status: {},
      statusItems: [
        {text: 'Активен', value:'active'},
        {text: 'Отключен', value:'disabled'},
      ],
      createTime: '',
      updateTime: '',
      cityList:[
        {text: 'Красноярск', value: 'krasnoyarsk'},
        {text: 'Севастополь', value: 'sevastopol'}],
      cities:[]
    }),

    //holder::multinet::100932
    //holder::partner::tequilastyle
    mounted(){
      this.init();
    },

    methods: {
      init(){
        this.show = true;
        //console.log('init');
        //console.log(this.refValidate('holder::multinet::100046'));
        if (!this.holder){
          this.holderId = null;
          this.name = '';
          this.shortName = '';
          this.type = this.typeItems[0];
          this.status = this.statusItems[0];
          this.ref = 'holder::' + this.type.value + '::';
          //this.resetValidation();
          this.$nextTick(() => this.resetValidation());
          //this.$emit('update-title', 'Пользователь: Новый');
          this.$emit('update-holder', {title: '<Новый>'});
        }
        else {
          this.holderId = this.holder;
          //потом сделать наоборот, если цифра то гет данные иначе новый
          this.get(this.holderId);
        }
        //console.log(this.holder);
      },

      refValidate(value){
        //console.log('vata');
        //console.log(value);
        return (this.type && (this.type.value == 'partner'
                    ? /holder::partner::(\w|-|\.)+$/.test(value)
                    : /holder::([a-z]+)::(\d)+$/.test(value)))
                || 'Недопустимые символы';
      },

      get(id){
        this.$store.dispatch("setLoading", true);
        try{
          this.$store.dispatch("getHolder", id)
            .then((response) =>{
              //console.log(response);
              if (response.data){
                this.holderId = response.data.id;
                this.name = response.data.name;
                this.shortName = response.data.shortName;
                this.ref = response.data.ref;
                this.type = this.typeItems.filter(v => (v.value == response.data.type))[0];
                this.status = this.statusItems.filter(v => (v.value == response.data.status))[0];
                this.createTime = response.data.createTime;
                this.updateTime = response.data.updateTime;
                this.cities= (Array.isArray(response.data.props.cities) && response.data.props.cities) || [];
                //this.resetValidation();
                this.$nextTick(() => this.resetValidation());
                this.$emit('update-holder', { title: this.name, type: this.type.value, holderId: this.holderId });
                //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
              }
              else{
                //this.$emit('update-holder', {holderId: null});
                // this.$store.dispatch("routerPushWrap", {path: '/holders'});
                throw new Error("Ошибка получения данных");
              }
            })
            .then(null, (error) =>{
              //console.log(error);
              this.$store.dispatch("showMessage", { text: 'Ошибка получения holder: ' + error.message, error: true });
              //this.$store.dispatch("routerPushWrap", { path: '/holders' });
            })
            .finally(() =>{
              this.$store.dispatch("setLoading", false);
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },
      
      submit(){
        if (this.validate()){
            //console.log(a);
          this.$store.dispatch("setLoading", true);
            //const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const holder = {
              id: this.holderId,
              type: this.type.value,
              status: this.status.value,
              ref: this.ref,
              name: this.name,
              shortName: this.shortName,
              cities: this.cities
            };
          //console.log(holder);
          let holderMethod = '';
          if (!this.holderId){
            holderMethod = 'postHolder';
          }
          else{
            holderMethod = 'putHolder';
          }
          
          try{
              this.$store.dispatch(holderMethod, holder)
                .then((response) => {
                  //получить ид пользователя и перейти на пользователя
                  //console.log(response);
                  //{ name: 'user', params: { userId: 123 }}
                  if (holderMethod == 'postHolder'){
                    this.$store.dispatch("routerPushWrap", {name: "Common", params: {holderId: '' + response.data.holderId}});//'/holder/' + response.data.holderId);
                    //this.$store.dispatch("routerPushWrap", {path:'/holder/' + response.data.holderId});
                    //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
                    //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
                  }
                  else if (holderMethod == 'putHolder'){
                    this.get(response.data.holderId);
                  }
                })
                .then(null,(error) => {
                    console.log(error);
                    //this.$store.dispatch("showMessage", {text: 'Ошибка получения данных', error: true});
                })
                .finally(() => {
                  this.$store.dispatch("setLoading", false);
                });
          }
          catch(e){
            this.$store.dispatch("showMessage", {text: e.message, error: true});
            this.$store.dispatch("setLoading", false);
          }
        }
      },

      transliterate(word){
        if (!word) return "";
        // const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};
        const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"","б":"b","ю":"yu"," ":"", "\"":"", "'":""};

        return word.split('').map(function (char) {
          return (a[char] === undefined ? char : a[char]);
        }).join("");
      },

      validate(){
        //console.log();
        return this.$refs.form.validate();
      },

      reset(){
        this.$refs.form.reset();
      },
      
      resetValidation(){
        if (this.$refs['form']){
          this.$refs.form.resetValidation();
        }
      },
    },
  };
  
  /*transliterate(text) {

    text = text
      .replace(/\u0401/g, 'YO')
      .replace(/\u0419/g, 'I')
      .replace(/\u0426/g, 'TS')
      .replace(/\u0423/g, 'U')
      .replace(/\u041A/g, 'K')
      .replace(/\u0415/g, 'E')
      .replace(/\u041D/g, 'N')
      .replace(/\u0413/g, 'G')
      .replace(/\u0428/g, 'SH')
      .replace(/\u0429/g, 'SCH')
      .replace(/\u0417/g, 'Z')
      .replace(/\u0425/g, 'H')
      .replace(/\u042A/g, '')
      .replace(/\u0451/g, 'yo')
      .replace(/\u0439/g, 'i')
      .replace(/\u0446/g, 'ts')
      .replace(/\u0443/g, 'u')
      .replace(/\u043A/g, 'k')
      .replace(/\u0435/g, 'e')
      .replace(/\u043D/g, 'n')
      .replace(/\u0433/g, 'g')
      .replace(/\u0448/g, 'sh')
      .replace(/\u0449/g, 'sch')
      .replace(/\u0437/g, 'z')
      .replace(/\u0445/g, 'h')
      .replace(/\u044A/g, "'")
      .replace(/\u0424/g, 'F')
      .replace(/\u042B/g, 'I')
      .replace(/\u0412/g, 'V')
      .replace(/\u0410/g, 'a')
      .replace(/\u041F/g, 'P')
      .replace(/\u0420/g, 'R')
      .replace(/\u041E/g, 'O')
      .replace(/\u041B/g, 'L')
      .replace(/\u0414/g, 'D')
      .replace(/\u0416/g, 'ZH')
      .replace(/\u042D/g, 'E')
      .replace(/\u0444/g, 'f')
      .replace(/\u044B/g, 'i')
      .replace(/\u0432/g, 'v')
      .replace(/\u0430/g, 'a')
      .replace(/\u043F/g, 'p')
      .replace(/\u0440/g, 'r')
      .replace(/\u043E/g, 'o')
      .replace(/\u043B/g, 'l')
      .replace(/\u0434/g, 'd')
      .replace(/\u0436/g, 'zh')
      .replace(/\u044D/g, 'e')
      .replace(/\u042F/g, 'Ya')
      .replace(/\u0427/g, 'CH')
      .replace(/\u0421/g, 'S')
      .replace(/\u041C/g, 'M')
      .replace(/\u0418/g, 'I')
      .replace(/\u0422/g, 'T')
      .replace(/\u042C/g, "'")
      .replace(/\u0411/g, 'B')
      .replace(/\u042E/g, 'YU')
      .replace(/\u044F/g, 'ya')
      .replace(/\u0447/g, 'ch')
      .replace(/\u0441/g, 's')
      .replace(/\u043C/g, 'm')
      .replace(/\u0438/g, 'i')
      .replace(/\u0442/g, 't')
      .replace(/\u044C/g, "'")
      .replace(/\u0431/g, 'b')
      .replace(/\u044E/g, 'yu');

    return text;
  };*/
</script>

<style scoped>

</style>