<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <v-container fluid class="bonus-background">
        <holder v-if="mode" :mode="mode"></holder>
        <holder-list v-else :paramTitle="true" :paramFilter="true" :paramPagination="true"/>
    </v-container>
</template>

<script>
  import HolderList from '@/components/HolderList';
  import Holder from '@/components/Holder';

  export default {
    name: 'Holders',
//    props: {holderType: String},
    props: ['mode'],
    components: {
      HolderList,
      Holder
    },
    watch: {
    },
    mounted() {
      //console.log(this.$route);
      //console.log(this.mode);
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
