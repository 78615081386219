<template>
    <v-card class="ma-2 yellow darken-3 align-self-center" dark elevation="12">
        <div class="d-flex flex-wrap flex-column justify-space-between">
            <div class="d-flex flex-wrap-reverse">
                <div>
                    <v-card-subtitle class="py-2 pl-4 text-wrap">Информация о пользователе</v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-title class="headline text-wrap" v-text="holder.name"></v-card-title>
                    <div class="wb text-wrap pl-4 pt-0 mt-n4">{{(holder.type == 'partner' ? 'Партнер' : 'Клиент') + ' (' + holder.ref +')'}}</div>
                    <v-divider></v-divider>

                    <div v-if="holder.account.length" class="d-flex flex-wrap mt-4 justify-start align-content-center">
                        <div class="text-wrap ml-4 pt-1">Счет:</div>
                        <template v-for="account in holder.account">
                            <div :key="account.id" class="d-flex flex-wrap align-center">
                                <v-btn small class="ml-4" outlined :to="'holder/' + idSplit(holder.id) + '/account/'">
                                    {{account.number}} ({{account.status}})
                                </v-btn>
                                <div v-if="account.extAccount && account.extAccount[0]">
                                    <v-icon class="ml-2">
                                        arrow_back
                                    </v-icon>
                                    <v-chip class="ml-2" label outlined>
                                        {{account.extAccount[0].externalCode}} ({{account.extAccount[0].type}})
                                    </v-chip>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div v-if="holder.phone.length" class="d-flex flex-wrap mt-2 justify-start align-content-center">
                        <div class="text-wrap ml-4 pt-1">Телефон:</div>
                        <template v-for="phone in holder.phone">
                            <div :key="phone.id" class="d-flex flex-wrap align-center">
                                <v-btn small class="ml-4" outlined :to="'/contact/' + phone.id">
                                    {{phone.phoneNumber}}
                                </v-btn>
                                <v-chip class="ml-2" label outlined>
                                    {{phone.status}}
                                </v-chip>
                            </div>
                        </template>
                    </div>
                    <span v-if="holder.username" class="text-wrap pa-4">Логин: {{holder.username}}</span>

                </div>
                <!--<div class="align-self-end">-->
                <v-avatar class="ma-3 align-self-end" size="125" tile>
                    <v-img v-if="holder.holderMarket && holder.holderMarket.logoFileName" :src="$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' +  holder.holderMarket.logoFileName + '.png'"></v-img>
                    <v-img v-else src="../../public/img/logo-john-doe.png"></v-img>
                </v-avatar>
                <!--</div>-->
            </div>
            <v-divider class="mt-2"></v-divider>
            <v-card-actions>
                <v-btn outlined class="ml-2" :to="url + '/'+ idSplit(holder.id)">
                    Перейти
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
  export default {
    name: "HolderInfo",
    props: ['holder','url'],
    methods: {
      idSplit(id) {
        let array = String.prototype.split.call(id, '::');
        if (id && array.length > 1 ) {
          //console.log(array[1]);
          return array[1];
        }
        else
          return '';
      },
    }
  };
</script>

<style scoped>
</style>