<template>
    <v-card class="ma-2 teal darken-4 align-self-center" dark elevation="12">
        <div class="d-flex flex-wrap flex-column justify-space-between">
            <div class="d-flex flex-wrap-reverse">
                <div>
                    <v-card-subtitle class="pa-2 ml-2 text-wrap">Каталог</v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-title class="headline text-wrap" v-text="holderMarket.name"></v-card-title>
                    <!--style="word-break: break-all;"-->
                    <!--<div class="headline ma-4 text-wrap">{{holderMarket.name}}</div>-->
                    <div class="d-flex pa-0 mt-n4 mx-4">
                        <v-chip label :href="holderMarket.siteUrl" target="_blank" class="transparent wb text-wrap pa-0 ma-0">
                            {{holderMarket.siteUrlName}}
                        </v-chip>
                    </div>
                    <!--тут надо сделать чтобы текст обрезался и был flex, но это какое-то дрочиво в vuetify-->
                    <div class="d-flex px-0 mx-4 mt-n1">
                        <v-chip label :href="$store.getters.getWindowProtocol + '//my.bonusarium.ru/partner/' + holderMarket.holderRouteId + '/'"
                                target="_blank"
                                class="transparent wb text-wrap pa-0 ma-0">
                            {{$store.getters.getWindowProtocol + '//my.bonusarium.ru/partner/' + holderMarket.holderRouteId + '/'}}
                        <!--<div class="long-and-truncated-with-child-corrected"><span>{{'http://my.bonusarium.ru/partner/' + holderMarket.holderRouteId + '/'}}</span></div>-->
                        </v-chip>
                    </div>

                    <!--<v-divider></v-divider>-->
                    <!--color="rgba(0,0,0,0.0)"-->
                    <!--<div class="mt-4 mx-4">Адрес:</div>-->
                    <!--<v-alert max-width="350" outlined class="mx-4 pa-0" color="rgba(0,0,0,0.0)">-->
                    <v-alert outlined max-width="20em" class="mx-4 pa-1 mt-2" v-if="holderMarket.addressStr">
                        <div class="text-body-2 mb-1">Адрес:</div>
                        <div class="text-wrap text-body-2" v-html="holderMarket.addressStr"></div>
                    </v-alert>
                    <!--<v-card-subtitle class="text-wrap">{{(holderMarket.type == 'partner' ? 'Партнер' : 'Пользователь') + ' (' + holderMarket.ref +')'}}</v-card-subtitle>-->
                    <!--<span v-if="item.username" class="text-wrap pa-4">Логин: {{holderMarket.username}}</span>-->
                </div>
                <v-avatar class="ma-3 align-self-end" size="125" tile>
                    <!--<v-img :src="$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' + holderMarket.logoFileName + '.png'"></v-img>-->
                    <v-img v-if="holderMarket && holderMarket.logoFileName" :src="$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' +  holderMarket.logoFileName + '.png'"></v-img>
                    <v-img v-else src="../../public/img/logo-john-doe.png"></v-img>
                </v-avatar>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn text outlined class="ml-2" :to="url">
                    Перейти
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
  export default {
    name: "HolderInfoCatalog",
    props: ['holderMarket','url'],
  };
</script>

<style scoped>
    .long-and-truncated-with-child-corrected {
        flex: 1;
        min-width: 0; /* or some value */
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>