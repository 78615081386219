<template>
    <!--<v-container fluid>-->
    <div class="flex-grow-1">
        <v-scale-transition>
            <v-card class="elevation-12 fill-height" color="rgb(0, 0, 0, 0.5)" v-show="show">
                <v-toolbar dense dark color="pink darken-4" v-if="paramTitle">
                    <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
                    <v-toolbar-title>Список пользователей</v-toolbar-title>
                </v-toolbar>
                <v-container fluid v-if="paramFilter">
                    <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select
                                dense
                                :items="typeItems"
                                v-model="type"
                                label="Тип"
                                outlined
                                hide-details
                                return-object
                            >
                                <!--v-on:change="changeFilter($event)"-->
                            </v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select
                                dense
                                :items="statusItems"
                                v-model="status"
                                label="Статус"
                                outlined
                                hide-details
                                return-object
                            >
                                <!--v-on:change="changeFilter($event)"-->
                            </v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="6">
                            <!--single-line-->
                            <!--class="mt-n2"-->
                            <!--prepend-icon="search"-->
                            <!--ref="filterNameField"-->
                            <!--:readonly="filterNameFieldDisabled"-->
                            <!--v-on:keyup.enter="changeFilterName"-->
                            <!--v-on:blur="changeFilterName($event)"-->
                            <v-text-field
                                dense
                                v-model="nameFilter"
                                label="Наименование"
                                outlined
                                hide-details
                                clearable
                                append-outer-icon="search"
                                @click:clear="clearNameFilter($event)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!--<v-row align="center">-->
                        <!--<v-col class="d-flex justify-end align-center" cols="12" sm="6">-->
                            <!--<v-btn block>-->
                                <!--<v-icon>search</v-icon>-->
                            <!--</v-btn>-->
                        <!--</v-col>-->
                    <!--</v-row>-->
                </v-container>
                <v-divider></v-divider>
                <!--<v-card-text>-->
                <!--<v-select-->
                    <!--v-model="selection"-->
                        <!--:items="['active','disabled']"-->
                        <!--multiple-->
                        <!--label="Select an option"-->
                <!--&gt;</v-select>-->
                <!--</v-card-text>-->
                <!--<v-card-title>-->
                    <!--<v-text-field-->
                            <!--v-model="search"-->
                            <!--append-icon="search"-->
                            <!--label="Search"-->
                            <!--single-line-->
                            <!--hide-details-->
                    <!--&gt;</v-text-field>-->
                <!--</v-card-title>-->
                <v-data-table
                        class="transparent"
                        ref="vDataTable"
                    :headers="computedHeaders"
                    :items="items"
                    show-expand
                    loader-height="1"
                    :expanded.sync="expanded"
                    v-bind:item-key="getItemKey()"
                    :items-per-page="itemsPerPageCount"
                    :server-items-length="totalItems"
                    hide-default-footer
                    @click:row="rowClick">
                    <!--:page.sync="pagination.page"-->
                    <!--:loading="loading"-->
                    <!--:single-expand="singleExpand"-->
                    <!--:search="search"-->
                    <!--:page.sync="pagination.page"-->
                    <!--@page-count="pageCount = $event"-->
                    <!--:options.sync="options"-->
                    <!--:footer-props=-->
                    <!--"{showFirstLastPage: true,-->
                    <!--firstIcon: 'first_page',-->
                    <!--lastIcon: 'last_page',-->
                    <!--prevIcon: 'chevron_left',-->
                    <!--nextIcon: 'chevron_right'}"-->

                    <!--<template v-slot:top>-->
                        <!--<v-toolbar flat>-->
                            <!--<v-toolbar-title>Holders</v-toolbar-title>-->
                            <!--&lt;!&ndash;<v-spacer></v-spacer>&ndash;&gt;-->
                            <!--&lt;!&ndash;<v-switch&ndash;&gt;-->
                            <!--&lt;!&ndash;v-model="singleExpand"&ndash;&gt;-->
                            <!--&lt;!&ndash;label="Single expand"&ndash;&gt;-->
                            <!--&lt;!&ndash;class="mt-2"&ndash;&gt;-->
                            <!--&lt;!&ndash;&gt;</v-switch>&ndash;&gt;-->
                        <!--</v-toolbar>-->
                    <!--</template>-->
                    <!--<template v-slot:header="{ props: { headers } }">-->
                        <!--<thead>-->
                        <!--<tr color="pink">-->
                            <!--<th v-for="h in headers" v-bind:key="h.value">-->
                                <!--<v-tooltip bottom>-->
                                    <!--<template v-slot:activator="{ on }">-->
                                        <!--<span v-on="on">{{h.text}}</span>-->
                                    <!--</template>-->
                                    <!--<span>{{h.text}}</span>-->
                                <!--</v-tooltip>-->
                            <!--</th>-->
                        <!--</tr>-->
                        <!--</thead>-->
                    <!--</template>-->
                    <template v-slot:item.id="{ item }">
                        {{ idSplit(item.id)}}
                    </template>
                    <template v-slot:item.type="{ item }">
                            {{ item.type == 'partner' ? 'Партнер' : 'Клиент' }}
                    </template>
                    <template v-slot:item.status="{ item }">
                        {{ item.status == 'active' ? 'Активен' : 'Не активен' }}
                    </template>
                    <template v-slot:item.phone="{ item }">
                        {{ item.phone.length ? item.phone.map((i) => (i.phoneNumber)).flat().join(',') : '' }}
                    </template>
                    <template v-slot:item.holderMarket="{ item }">
                        {{ item.holderMarket && item.holderMarket.id ? item.holderRouteId : 'Не размещен' }}
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length"
                            :key="getItemKey(item)"
                            :class="{'ma-0 pa-0': true,
                                    'expanded-closing': !transitioned[getItemId(item)]}"
                            style="height: auto;"
                            >
                            <v-expand-transition>
                                <div v-show="transitioned[getItemId(item)]" class="pa-0 ma-0">
                                    <!--<v-container fluid class="d-flex pa-0 ma-0">-->
                                        <div class="d-flex justify-space-around flex-wrap pa-0 ma-0">
                                            <holder-info :holder="item" url="/holder"></holder-info>
                                            <template v-if="item.holderMarket && item.holderMarket.id">
                                                <holder-info-catalog :holderMarket="item.holderMarket" :url="'/holder/' + idSplit(item.id) + '/catalog'"></holder-info-catalog>
                                            </template>
                                            <v-card class="ma-2 pink accent-2 align-self-center" dark elevation="12"
                                                v-if="item.holderDiscount.length"
                                                >
                                                <div class="d-flex flex-wrap flex-column justify-space-between">
                                                    <div class="d-flex flex-wrap-reverse">
                                                        <div>
                                                            <v-card-subtitle class="pa-2 ml-2 text-wrap">Активные акции</v-card-subtitle>
                                                            <v-divider></v-divider>
                                                            <v-card-title class="headline" v-text="item.name"></v-card-title>
                                                            <v-card-subtitle class="wb text-wrap">{{(item.type == 'partner' ? 'Партнер' : 'Пользователь') + ' (' + item.ref +')'}}</v-card-subtitle>
                                                            <span v-if="item.username" class="text-wrap pa-4">Логин: {{item.username}}</span>
                                                        </div>
                                                        <v-avatar class="ma-3" size="125" tile>
                                                            <!--<v-img :src="$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' + item.holderMarket.logoFileName + '.png'"></v-img>-->
                                                            <!--<v-img src="../../public/img/logo-emerald.png"></v-img>-->
                                                            <v-img v-if="item.holderMarket && item.holderMarket.logoFileName" :src="$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' +  item.holderMarket.logoFileName + '.png'"></v-img>
                                                            <v-img v-else src="../../public/img/logo-john-doe.png"></v-img>
                                                        </v-avatar>
                                                    </div>
                                                    <v-card-actions>
                                                        <v-btn text outlined class="ml-2 mt-5" :to="'/holder/' + idSplit(item.id) + '/discount'">
                                                            Перейти
                                                        </v-btn>
                                                    </v-card-actions>
                                                </div>
                                            </v-card>
                                            <v-card class="ma-2 cyan accent-4 align-self-center" dark elevation="12"
                                                v-if="item.type == 'partner && false'">
                                                <div class="d-flex flex-wrap flex-column justify-space-between">
                                                    <div class="d-flex flex-wrap-reverse">
                                                        <div>
                                                            <v-card-subtitle class="pa-2 ml-2 text-wrap">Сертификаты</v-card-subtitle>
                                                            <v-divider></v-divider>
                                                            <v-card-title class="headline" v-text="item.name"></v-card-title>
                                                            <v-card-subtitle class="wb text-wrap">{{(item.type == 'partner' ? 'Партнер' : 'Пользователь') + ' (' + item.ref +')'}}</v-card-subtitle>
                                                            <span v-if="item.username" class="text-wrap pa-4">Логин: {{item.username}}</span>
                                                        </div>
                                                        <v-avatar class="ma-3" size="125" tile>
                                                            <v-img v-if="item.holderMarket && item.holderMarket.logoFileName" :src="$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' +  item.holderMarket.logoFileName + '.png'"></v-img>
                                                            <v-img v-else src="../../public/img/logo-john-doe.png"></v-img>
                                                            <!--<v-img src="../../public/img/logo-emerald.png"></v-img>-->
                                                        </v-avatar>
                                                    </div>
                                                    <v-card-actions>
                                                        <v-btn text outlined class="ml-2 mt-5" :to="'/holder/' + idSplit(item.id)">
                                                            Перейти
                                                        </v-btn>
                                                    </v-card-actions>
                                                </div>
                                            </v-card>
                                        </div>
                                    <!--</v-container>-->
                                    <!--<div class="pa-2" style="min-height: 100px;">-->
                                        <!--<v-banner-->
                                                <!--elevation="12"-->
                                                <!--icon="$mdiAccount"-->
                                        <!--&gt;-->
                                            <!--{{ item.name }}-->
                                        <!--</v-banner>-->
                                    <!--</div>-->
                                </div>
                            </v-expand-transition>
                        </td>
                    </template>
                    <template v-slot:item.data-table-expand="props">
                        <v-icon :class="{ 'v-data-table__expand-icon': true,
                                          'v-data-table__expand-icon--active': props.isExpanded && transitioned[getItemId(props.item)] }"
                        >
                            <!--@click="toggleExpand(props)"-->
                            expand_more
                        </v-icon>
                    </template>
                    <!--сделать группировку по типам партнеров?-->
                    <!--<no-data-text no-results-text expand-icon server-items-length loading-text expanded=items[]>-->

                    <!--<template v-slot:top>-->
                    <!--<v-text-field-->
                    <!--v-model="search"-->
                    <!--label="Search (UPPER CASE ONLY)"-->
                    <!--class="mx-4"-->
                    <!--&gt;</v-text-field>-->
                    <!--</template>-->
                </v-data-table>
                <!--<v-container fluid>-->
                <div class="pagination" v-if="paramPagination">
                    <!--<v-container fluid pa-0>-->
                    <v-row class="d-flex align-center pa-0 ma-0">
                        <!--<v-col class="d-flex justify-start flex-grow-0 flex-shrink-1" sm="2" xs="2">-->
                        <v-col class="flex-grow-0 flex-shrink-1">
                            <!--class="ml-4 mr-4"-->
                            <v-select 
                                    v-model="itemsPerPage"
                                    :items="itemsPerPageItems"
                                    label="Строк" outlined hide-details return-object dense
                            >
                                <!--v-on:change="changePages($event)"-->
                            </v-select>
                        </v-col>
                        <!--<v-col class="d-flex justify-center">-->
                        <v-col class="">
                                <v-pagination
                                        @input="paginationChangeHandler"
                                        :value="page"
                                        :length="pageCount"
                                        :total-visible="6"
                                ></v-pagination>
                        </v-col>
                        <!--<v-col class="d-flex text-no-wrap ml-4 mr-4 flex-grow-0 flex-shrink-1 justify-end">-->
                        <v-col class="text-no-wrap flex-grow-0 flex-shrink-1 justify-end">
                            <v-chip outlined label>
                                {{(page - 1)* itemsPerPageCount + 1}} - {{(page * itemsPerPageCount > totalItems ? totalItems : page * itemsPerPageCount)}} из {{totalItems}}
                            </v-chip>
                        </v-col>
                    </v-row>
                    <!--</v-container>-->
                </div>
                <!--</v-container>-->
                <!--rounded-->
                <!--contained-->
                <!--<button type="button" class="transition-swing v-btn v-btn&#45;&#45;bottom v-btn&#45;&#45;contained v-btn&#45;&#45;fab v-btn&#45;&#45;fixed v-btn&#45;&#45;right v-btn&#45;&#45;round theme&#45;&#45;light v-size&#45;&#45;large primary" aria-label="Scroll to top" title="Scroll to top" style="z-index: 6; margin-bottom: 88px; transform-origin: center center;"><span class="v-btn__content"><span aria-hidden="true" class="v-icon notranslate theme&#45;&#45;light"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"></path></svg></span></span></button>-->
            </v-card>
        </v-scale-transition>
        <v-fab-transition>
            <!--large-->
            <v-btn  to="/holder/add"
                    v-show="!addHidden"
                    dark
                    elevation="12"
                    fixed
                    fab
                    bottom
                    right
                    class="mb-16 mr-8 pink">
                <v-icon>add</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
    <!--</v-container>-->
</template>

<script>
  import HolderInfo from '@/components/HolderInfo';
  import HolderInfoCatalog from '@/components/HolderInfoCatalog';
  export default {
    // props:{
    //   holderType: {type: String, default: ''}
    // },
    props: ['paramTitle', 'paramFilter', 'paramPagination'],
    name: 'HolderList',
    components: {
      HolderInfo,
      HolderInfoCatalog
    },
    data(){
      return {
        addHidden: true,
        show: false,
        //filter
        typeItems: [{text: 'Все', value: ''}, {text: 'Партнер', value: 'partner'}, {text: 'Клиент',value: 'person'}],
        statusItems: [{text: 'Все', value: ''}, {text: 'Активен', value: 'active'}, {text: 'Не активен',value: 'disabled'}],
        itemsPerPageItems: [{text: '5', value: 5},{text: '10', value: 10},{text: '20', value: 20},{text: 'Все', value: -1}],
        items: [],
        totalItems: 0,

        //table
        transitioned: [],
        expanded: [],
        singleExpand: true,
        closeTimeouts: {},
        headers: [
          {text: 'Id', align: 'left', sortable: false, value: 'id', divider: true, mobile: true},
          { text: 'Наименование', value: 'name', mobile: true, divider: true, sortable: false},
          { text: 'Тип', value: 'type', divider: true, mobile: false, sortable: false},
          { text: 'Статус', value: 'status', divider: true, mobile: false, sortable: false},
//          { text: 'Ref', value: 'ref', divider: true, mobile: false, sortable: false},
          { text: 'Каталог path', value: 'holderMarket', divider: true, mobile: false, sortable: false},
//          { text: 'Телефон', value: 'phone', divider: true, mobile: false, sortable: false},
          { text: 'Создан', value: 'createTime', divider: true, mobile: false, sortable: false},
          { text: '', value: 'data-table-expand', mobile: true },
        ],
      }
    },
    //https://ru.vuejs.org/v2/api/#watch
    watch: {
      '$route.query': {
        //отраьатывает на каждый параметр при старте
        handler: function(newVal, oldVal) {
          //console.log(oldVal);
          //console.log(newVal);
          if ( (oldVal && oldVal.name !== newVal.name)
                || (oldVal && oldVal.status !== newVal.status)
                || (oldVal && oldVal.type !== newVal.type)
            || (oldVal && oldVal.itemsPerPage !== newVal.itemsPerPage)){
            if (this.page !== 1){
              this.page = 1;
            }
          }

          /*if (oldVal && oldVal.pages !== newVal.pages){
            if (newVal.pages > 0){
              this.pagination.itemsPerPage = +newVal.pages;
            }
            else{
              this.pagination.itemsPerPage = this.pagination.totalItems;
            }
            //this.pagination.page = 1;
            //this.get('pages');
          }*/


          if (newVal != oldVal) {
            //this.term = this.$route.query.term;
            //console.log('watch');
            this.get('watch');
          }
        },
        //immediate: true
      },

      options: {
        handler (i) {
          //console.log(i);
          /*if (this.options.resetPage){
            this.options.resetPage = false;
            this.options.page = 1;
          } */
          //this.getDataFromApi();
          //this.get('watch');
        },
        deep: true,
      },
      
      singleExpand (v) {
        if (!v) return;
        // Single expand enabled. Hide all but the first expanded item
        this.expanded.forEach((item, i) => {
          if (i > 0) this.closeExpand(item);
        })
      }
    },

    mounted(){
      //console.log('htype'+this.holderType);
      //этоубрать из пропертиес и передавать как query
/*
      const findItems = this.typeItems.filter((item) => (item.value == this.holderType));
      //console.log(findItems);

      if (findItems.length){
        this.type = findItems[0];
      }
      else{
        this.type = this.typeItems[0];
      }
*/
      //this.status = this.statusItems[0];
      //this.pagesSelected = this.itemsPerPageItems[1];
      //this.$set(this.options, 'resetPage', false);
      this.init();
    },

    computed: {
      itemsPerPage: {
        set(v){
          if (this.$route.query.itemsPerPage !== v.value){
            this.$router.push({ query: { ...this.$route.query, itemsPerPage: v.value } });
          }
        },
        get(){
          const findItems = this.itemsPerPageItems.filter((item) => (item.value == this.$route.query.itemsPerPage));
          //console.log(findItems);

          if (findItems.length){
            return findItems[0];
          }
          else{
            return this.itemsPerPageItems[1];
          }
        }
      },

      //добавить в вотч
      status:{
        set(status){
          if (this.$route.query.status !== status.value){
            this.$router.push({ query: { ...this.$route.query, status: status.value } });
          }
        },
        get(){
          const findItems = this.statusItems.filter((item) => (item.value == this.$route.query.status));
          //console.log(findItems);

          if (findItems.length){
            return findItems[0];
          }
          else{
            //this.status = this.statusItems[0];
            //if (this.$route.query.status !== this.statusItems[0].value){
              //this.$router.push({ query: { ...this.$route.query, status: this.statusItems[0].value } });
            //}
            return this.statusItems[0];
          }
        }
      },

      type:{
        set(type){
          //console.log('saaa');
          if (this.$route.query.type !== type.value){
            this.$router.push({ query: { ...this.$route.query, type: type.value } });
          }
        },
        get(){
          const findItems = this.typeItems.filter((item) => (item.value == this.$route.query.type));
          //console.log(findItems);

          if (findItems.length){
            return findItems[0];
          }
          else{
            return this.typeItems[0];
          }
        }
      },

      page:{
        set(page){
          this.$router.push({ query: { ...this.$route.query, page } });
        },
        get(){
          if (this.$route.query.page){
            return +this.$route.query.page;
          }
          else
            return 1;
        }
      },

      nameFilter:{
          set(name){
            this.$router.push({ query: { ...this.$route.query, name } });
          },
          get(){
            if (this.$route.query.name){
              return this.$route.query.name;
            }
            else
                return '';
          }
        },

      itemsPerPageCount(){
        if (this.itemsPerPage.value > 0){
          return this.itemsPerPage.value;
        }
        else{
          return this.totalItems;
        }
      },

      pageCount() {
        //console.log(this.pagination);
        if (!this.itemsPerPage.value || !this.totalItems) {
          return 0;
        }
        if (this.itemsPerPage.value == -1){
          return 1;
        }
        return Math.ceil(this.totalItems / this.itemsPerPage.value);
      },

      computedHeaders () {
        return this.headers.filter((i) => (this.$vuetify.breakpoint.mdAndUp || (this.$vuetify.breakpoint.smAndDown && i.mobile)))
          .map((i) => {
            //i.class = 'myheader';
            //:class="{'ma-0 pa-0': true,            'expanded-closing': !transitioned[getItemId(item)]}"
            return i;
          });
      }
    },

    methods: {
      idSplit(id) {
        let array = String.prototype.split.call(id, '::');
        if (id && array.length > 1 ) {
          //console.log(array[1]);
          return array[1];
        }
        else
          return '';
      },

      init(){
        this.get('getH on mounted');
        this.addHidden = false;
        this.show = true;
      },

      rowClick(item, props){
        this.toggleExpand(props);
        //console.log(a);
        //console.log(b);
      },

      clearNameFilter(e){
        //this.filterNameFieldDisabled = false;
        this.nameFilter = '';
        this.page = 1;
        //this.get('clear');
        //console.log(e);
      },

      paginationChangeHandler(pageNumber) {
        if (this.page != pageNumber){
          this.page = pageNumber;
          //this.get('getH on paginator');
        }
      },

      get(a){
        //console.log(a);
        //this.loading = true;
        this.$store.dispatch("setLoading", true);

        const reqParams = {
          type: this.type.value,
          status: this.status.value,
          ref: '',
          name: this.nameFilter || '',
          username: '',
          page: this.page,
          itemsPerPage: this.itemsPerPageCount
        };

        //console.log(reqParams);
        let reqMethod = 'getHolders';

        this.$store.dispatch(reqMethod, reqParams)
          .then((response) => {
            //console.log(response);
            //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
            this.items = response.data.data;
            this.totalItems = response.data.count;
          })
          .then(null,(error) => {
            //console.log(error);
            this.$store.dispatch("showMessage", {text: 'Ошибка получения holders: ' + error.message, error: true});
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
            //this.loading = false;
          });
      },

      closeAll(){
        this.$nextTick(() => this.expanded.forEach(i => this.closeExpand(i)));
      },

      toggleExpand (props) {
        const item = props.item;
        const id = this.getItemId(item);
        if (props.isExpanded && this.transitioned[id]) {
          // If we're expanded and not in the process of closing, close
          this.closeExpand(item);
        } else {
          // If we're closed or in the process of closing, expand
          // Stop us from closing if a close transition was started
          clearTimeout(this.closeTimeouts[id]);
          // Tell v-data-table to add the expansion content for the item
          props.expand(true);
          // Show expansion content with transition animation after it's had time to get added to the DOM
          this.$nextTick(() => this.$set(this.transitioned, id, true));
          // Hide all other expanded items if single-expand
          if (this.singleExpand) this.$nextTick(() => this.expanded.forEach(i => i !== item && this.closeExpand(i)));
        }
      },

      closeExpand (item) {
        //console.log(item);
        const id = this.getItemId(item);
        // Mark that this item is in the process of closing
        this.$set(this.transitioned, id, false);
        // Remove expansion content from DOM after transition animation has had enough time to finish
        this.closeTimeouts[id] = setTimeout(() => this.$refs.vDataTable.expand(item, false), 600);
      },

      getItemId (item) {
        return item[this.getItemKey()]; // Must be uid of record (would be nice if v-data-table exposed a "getItemKey" method)
        //return item.name;
      },

      getItemKey(){
        return "id";
        //return "name";
      }
    }
  };
</script>

<style scoped>
</style>

<!--<v-card id="app" color="rgb(0, 0, 0, 0.2)" width="354" height="100%">-->
    <!--<v-layout justify-space-between column fill-height>-->
        <!--<v-flex>-->
            <!--<v-card-title primary-title>-->
                <!--<h3 class="headline ma-1"></h3>-->
            <!--</v-card-title>-->
            <!--<v-card-text>-->
                <!--<v-flex>testing</v-flex>-->
            <!--</v-card-text>-->
        <!--</v-flex>-->
        <!--<v-flex style='height:100px'>-->
            <!--<v-layout align-end row fill-height>-->
                <!--<v-flex>-->
                    <!--<v-divider></v-divider>-->
                    <!--<v-card-actions>-->
                        <!--<v-btn color="blue darken-3" block>View Examples</v-btn>-->
                    <!--</v-card-actions>-->
                <!--</v-flex>-->
            <!--</v-layout>-->
        <!--</v-flex>-->
    <!--</v-layout>-->
<!--</v-card>-->